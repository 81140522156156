import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

function ProjectSelect(props) {
  const { projects, activeProject, onActiveProjectChange } = props;

  if (!activeProject) {
    return null;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="select-project-label">Project</InputLabel>
      <Select
        labelId="select-project-label"
        id="select-project"
        value={activeProject.id}
        label="Project"
        onChange={onActiveProjectChange}
      >
        {
          projects.map(
            (project, idx) => (
              <MenuItem
                value={project.id}
                idx={idx}
              >
                {project.attributes.name}
              </MenuItem>
            ),
          )
        }
      </Select>
    </FormControl>
  );
}

export default ProjectSelect;
