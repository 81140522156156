import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

import SampleSearchAutocomplete from './Samples/SampleSearchAutocomplete';
import SamplesList from './Samples/SamplesList';
import RunStatusList from './Runs/RunStatusList';

function Project() {
  const { activeProject } = useOutletContext();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [samples, setSamples] = useState(null);
  const [runs, setRuns] = useState(null);
  const [visibleRunsInputValue, setVisibleRunsInputValue] = useState("");
  const [recentRuns, setRecentRuns] = useState(null)
  const [visibleRuns, setVisibleRuns] = useState([null, null, null, null, null, null]);
  const [activeRun, setActiveRun] = useState(null);

  useEffect(() => {
      if (!activeProject || !recentRuns) {
          return () => {};
      }

      let newVisibleRuns;
      if (!visibleRuns.length) {
          newVisibleRuns = recentRuns;
      } else {
          newVisibleRuns = runs.filter(
              run => run.attributes.name.startsWith(visibleRunsInputValue)
          ).slice(0, 6)
      }

      if (newVisibleRuns.length < 6) {
          newVisibleRuns.push(...Array(6 - newVisibleRuns.length).fill(1))
      }

      let timer = setTimeout(() => {
          setVisibleRuns(newVisibleRuns.slice(0, 6))
      }, 1000);

      return () => clearTimeout(timer);
  }, [
    getAccessTokenSilently,
    visibleRunsInputValue,
    activeProject,
    recentRuns,
    activeRun,
    runs,
    visibleRuns
  ]);

  useEffect(() => {
    if (!activeProject) {
      return;
    }

    setActiveRun(null)
    setSamples(null)
    setVisibleRuns(Array(6).fill(null))
  }, [activeProject, visibleRunsInputValue])

  useEffect(() => {
    if (!activeRun) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${activeRun.id}/lib_seqs`,
            {
              method: 'GET',
              headers,
            },
          )
          })
      .then((res) => res.json())
      .then((response) => {
        setSamples(response.data.sort((a, b) => a.id - b.id));
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, activeRun, setSamples]);

  useEffect(() => {
    if (!activeProject) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/projects/${activeProject.id}/sequencing_runs`,
          {
            method: 'GET',
            headers,
          },
        )
      })
      .then((res) => res.json())
      .then((response) => {
        const allRuns = response.data.sort((a,b) => {
            return new Date(b.attributes.created_at) - new Date(a.attributes.created_at)
        })

        const recentRuns = allRuns.slice(0,6);

        setRecentRuns(recentRuns);
        setRuns(allRuns);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, activeProject]);

  let searchSection = (
    <Box sx={{ marginTop: '15px'}}>
      <SampleSearchAutocomplete
        activeProject={activeProject}
        setSamples={setSamples} 
      />
    </Box>
  )

  if (activeRun) {
    searchSection = (
      <Box sx={{ textAlign: 'right', marginTop: '50px'}}>
        <Chip
            label={activeRun.attributes.name}
            color="primary"
            onDelete={() => {
                setActiveRun(null)
                setSamples(null)
            }}
            size="medium" 
        />
      </Box>
    )
  }

  let visibleRunsContent = null;
  if (visibleRuns) {
    visibleRunsContent = (
        <RunStatusList
          runs={visibleRuns}
          activeRun={activeRun}
          setActiveRun={setActiveRun} 
          setSamples={setSamples} 
        />
    )
  }

  return (
    <Grid container maxWidth="lg" spacing={2} sx={{ m: 'auto', mt: 1, paddingRight: '30px' }}>
      <Grid item xs={12} sm={8} sx={{marginTop: '50px'}}>
        <p style={{color: '#797979', marginBottom: '2px', marginTop: '2px'}}>
            <b>SEQUENCING DATASETS</b>
        </p>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ marginTop: '15px'}}>
            <TextField fullWidth label="Search Sequencing Datasets" variant="outlined" onChange={event => setVisibleRunsInputValue(event.target.value)} value={visibleRunsInputValue} />
        </Box>
      </Grid>
      {visibleRunsContent}
      <Grid item xs={12} sm={4} sx={{ marginTop: '50px'}}>
        <p style={{color: '#797979', marginBottom: '2px', marginTop: '2px'}}><b>SAMPLES</b></p>
      </Grid>
      <Grid item xs={12} sm={8}>
          {searchSection}
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={2} sx={{ marginBottom: '20px' }}>
          <SamplesList samples={samples} activeProject={activeProject} />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Project;
