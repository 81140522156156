import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';
import orange from '@mui/material/colors/orange';
import Nav from './Nav';

const theme = createTheme({
  palette: {
    primary: orange,
    secondary: grey,
    default: grey
  },
});

function Layout() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [activeProject, setActiveProject] = React.useState(null);
  const [projects, setProjects] = React.useState(null);

  const handleActiveProjectChange = (event) => {
    const newActiveProject = projects.find((project) => project.id === event.target.value);
    setActiveProject(newActiveProject);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/1/projects`,
          {
            method: 'GET',
            headers,
          },
        )
      })
      .then((res) => res.json())
      .then((response) => {
        setActiveProject(response.data[0]);
        setProjects(response.data);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <ThemeProvider theme={theme}>
      <Nav
        projects={projects}
        activeProject={activeProject}
        onActiveProjectChange={handleActiveProjectChange}
      />
      <Container maxWidth={false} disableGutters sx={{ overflowX: 'hidden' }}>
        <Outlet context={{ activeProject }} />
      </Container>
    </ThemeProvider>
  );
}

export default Layout;
