import React from 'react';

import {
  LinearProgress, Box, TableContainer, Table, TableHead, TableCell, TableRow, TableBody,
} from '@mui/material';

function RunYieldSummary(props) {
  const {
    analysis,
  } = props;

  const yieldTitleContent = (
    <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
      <p><b>YIELD</b></p>
    </Box>
  );

  if (!analysis) {
    return (
      <>
        {yieldTitleContent}
        <LinearProgress />
      </>
    );
  }

  return (
    <>
      {yieldTitleContent}
      <TableContainer component={Box}>
        <Table>
          <TableHead sx={{ backgroundColor: '#bdbdbd' }}>
            <TableRow>
              <TableCell sx={{ borderBottom: 'none' }}>&nbsp;</TableCell>
              <TableCell align="center"><b>Total</b></TableCell>
              <TableCell align="center"><b>After Downsampling</b></TableCell>
              <TableCell align="center"><b>After QC</b></TableCell>
              <TableCell align="center"><b>After Human Read Removal</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#bdbdbd', border: 'none' }}><b>Reads</b></TableCell>
              <TableCell align="center">{analysis.attributes.raw_total_reads.toLocaleString()}</TableCell>
              <TableCell align="center">{analysis.attributes.downsampled_reads.toLocaleString()}</TableCell>
              <TableCell align="center">{analysis.attributes.qc_filtered_reads.toLocaleString()}</TableCell>
              <TableCell align="center">{analysis.attributes.human_reads_removed_reads.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#bdbdbd', border: 'none' }}><b>Megabases</b></TableCell>
              <TableCell align="center">
                {analysis.attributes.raw_total_megabases.toFixed(2)}
                {' '}
                MBs
              </TableCell>
              <TableCell align="center">
                {analysis.attributes.downsampled_megabases.toFixed(2)}
                {' '}
                MBs
              </TableCell>
              <TableCell align="center">
                {analysis.attributes.qc_filtered_megabases.toFixed(2)}
                {' '}
                MBs
              </TableCell>
              <TableCell align="center">
                {analysis.attributes.human_reads_removed_megabases.toFixed(2)}
                {' '}
                MBs
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default RunYieldSummary;
