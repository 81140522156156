import { useParams } from 'react-router-dom';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import logo from './dzd-logo.svg';
import ProjectSelect from './Projects/ProjectSelect';
import UserDropdown from './User/UserDropdown';

function ResponsiveAppBar(props) {
  const params = useParams();
  const { projects, activeProject, onActiveProjectChange } = props;

  let projectSelectContent = null;
  if (!params.sampleId) {
    projectSelectContent = (
      <ProjectSelect
        projects={projects}
        activeProject={activeProject}
        onActiveProjectChange={onActiveProjectChange}
      />
    );
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 'none', borderBottom: '#bdbdbd 2px solid' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ padding: '0 40px 0 0' }}>
            <img src={logo} alt="dzd-logo" />
          </Box>

          <Box sx={{ padding: '15px 20px 15px 0', minWidth: '150px' }}>
            {projectSelectContent}
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }} />

          <Box sx={{ flexGrow: 0 }}>
            <UserDropdown />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
