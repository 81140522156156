import React from 'react';

import { Grid } from '@mui/material';

function AMRPredictionRow(props) {
  const {
    prediction,
    isDiagnosticView,
  } = props;

  let callColor = '#0081ff';
  let callText = 'Intermediate';
  if (prediction.attributes.amr_call === 'R') {
    callColor = '#ff9800';
    callText = 'Resistant';
  } else if (prediction.attributes.amr_call === 'I') {
    callColor = 'green';
    callText = 'Susceptible';
  }

  let confidenceContent = null;
  if (!isDiagnosticView) {
    const confidenceColor = prediction.attributes.model_performance_label === 'H'
      ? '#0081ff' : 'green';

    const confidenceText = prediction.attributes.model_performance_label === 'H'
      ? 'High' : 'Very High';

    confidenceContent = (
      <Grid item xs={12} sm={5}>
        <p>
          Confidence:
          {' '}
          <b><span style={{ color: confidenceColor }}>{confidenceText}</span></b>
        </p>
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={{
        pl: 3, pr: 1, textAlign: 'center', borderBottom: '#bdbdbd 2px solid', borderLeft: '#bdbdbd 2px solid',
      }}
    >
      <Grid item xs={12} sm={isDiagnosticView ? 10 : 5} sx={{ textAlign: 'left' }}>
        <p><b>{prediction.attributes.model_drug}</b></p>
      </Grid>
      <Grid item xs={12} sm={2}>
        <b><p style={{ color: callColor }}>{callText}</p></b>
      </Grid>
      {confidenceContent}
    </Grid>
  );
}

export default AMRPredictionRow;
