import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

function SampleListItem(props) {
  const {
    project,
    sample,
  } = props;

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { ref, inView } = useInView();

  const [processingState, setProcessingState] = useState(null);
  const [activeAnalysis, setActiveAnalysis] = useState(null);
  const [activeAnalysisDetails, setActiveAnalysisDetails] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!activeAnalysis) {
      return;
    }

    if (activeAnalysisDetails) {
      return;
    }

    if (!inView) {
      return;
    }

    getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/analyses/${activeAnalysis.id}?extra_fields=positive_species_calls_ct`,
            {
              method: 'GET',
              headers,
            },
          )
      })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data) {
          return;
        }

        setActiveAnalysisDetails(response.data);
      })
      .catch((error) => console.error(error));
  }, [getAccessTokenSilently, isAuthenticated, activeAnalysisDetails, inView, activeAnalysis]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (activeAnalysis) {
      return;
    }

    if (!inView) {
      return;
    }

    getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/lib_seqs/${sample.id}/analyses?primary_only=True`,
            {
              method: 'GET',
              headers,
            },
          )
      })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data.length) {
          setProcessingState('awaiting_analysis');
          return;
        }

        const activeAnalysis = response.data[0];
        const createdAt = activeAnalysis.attributes.created_at;
        const diagnosticReport = activeAnalysis.attributes.pdf_diagnostic_report;

        setActiveAnalysis(activeAnalysis);

        if (diagnosticReport) {
            setProcessingState('analysis_ready');
            return;
        }

        const hoursSinceAnalysisStarted = Math.abs(new Date() - new Date(createdAt)) / 36e5 
        if (hoursSinceAnalysisStarted > 6) {
            setProcessingState('analysis_failed');
            return;
        }

        setProcessingState('analysis_running');
      })
      .catch((error) => console.error(error));
  }, [getAccessTokenSilently, isAuthenticated, inView, activeAnalysis, sample]);

  let statusContent = <CircularProgress size={20} sx={{ mt: 2 }} />;
  let isClickable = false
  if (processingState === 'awaiting_analysis') {
    statusContent = (<p><b style={{ color: '#ff9800' }}>Awaiting analysis</b></p>);
  } else if (processingState === 'analysis_ready') {
    isClickable = true
    statusContent = (<p style={{ color: 'green' }}><b>Results ready</b></p>);
  } else if (processingState === 'analysis_failed') {
    statusContent = (<p style={{ color: 'red' }}><b>Analysis failed</b></p>);
  } else if (processingState === 'analysis_running') {
    statusContent = (<p style={{ color: '#0081ff' }}><b>Analyzing</b></p>);
  }

  let yieldContent = <p>-</p>;
  if (activeAnalysis && activeAnalysis.attributes.raw_total_megabases) {
    const yieldAsFormattedString = activeAnalysis.attributes.raw_total_megabases.toLocaleString(
      'en-US',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    );

    yieldContent = (
      <p>
        {yieldAsFormattedString}
        {' '}
        MBs
      </p>
    );
  }

  let speciesIDContent = <p>-</p>;
  if (activeAnalysisDetails) {
    let speciesIDCall = 'Negative';
    let pluralityOfSpeciesIDCall = null;
    if (activeAnalysisDetails.attributes.positive_species_calls_ct > 0) {
      speciesIDCall = 'Positive';

      pluralityOfSpeciesIDCall = (activeAnalysisDetails.attributes.positive_species_calls_ct === 1)
        ? ' (monomicrobial)' : ' (polymicrobial)';
    }

    speciesIDContent = (
      <p>
        <b>
          {speciesIDCall}
          {pluralityOfSpeciesIDCall}
        </b>
      </p>
    );
  }

  return (
    <Link ref={ref} to={isClickable ? `/projects/${project?.id}/runs/${sample?.attributes.sequencing_run_id}/samples/${sample?.id}` : null} style={{ textDecoration: 'none', color: 'black', cursor: isClickable ? 'pointer' : 'default', opacity: isClickable ? 1 : 0.5}}>
      <Box>
        <Grid
          container
          sx={{
              pl: 2, pr: 1, textAlign: 'center', borderBottom: '#bdbdbd 2px solid', '&:hover': { backgroundColor: isClickable ? '#ff980015' : '' },
          }}
        >
          <Grid item xs={12} sm={2} sx={{ textAlign: 'left' }}>
            <p>
              <b>
                {
                  sample.attributes.sample_name
                  || sample.attributes.library_id
                  || sample.attributes.id
                }
              </b>
            </p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            { statusContent }
          </Grid>
          <Grid item xs={12} sm={1} sx={{ textAlign: 'center' }}>
            <p>{sample.attributes.barcode_id}</p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            { yieldContent }
          </Grid>
          <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
            { speciesIDContent }
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>{new Date(sample.attributes.created_at).toDateString()}</p>
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
}

export default SampleListItem;
