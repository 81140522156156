import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import AMRPredictionRow from '../AMRPredictions/AMRPredictionRow';

function IdentifiedSpeciesRow(props) {
  const { identifiedSpecies, isDiagnosticView } = props;

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [amrPredictions, setAmrPredictions] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!identifiedSpecies || !identifiedSpecies.attributes.species_call_positive) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/identified_species/${identifiedSpecies.id}/amr_model_predictions`,
            {
              method: 'GET',
              headers,
            },
          )
      })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data.length) {
          setAmrPredictions(null);
          return;
        }

        const highConfidenceAmrPredictions = response.data
          .filter((item) => ['H', 'VH'].includes(item.attributes.model_performance_label)).sort((a, b) => a.attributes.model_drug.localeCompare(b.attributes.model_drug));

        setAmrPredictions(highConfidenceAmrPredictions);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, identifiedSpecies]);

  let amrPredictionsContent = null;
  if (identifiedSpecies.attributes.species_call_positive && amrPredictions) {
    amrPredictionsContent = amrPredictions.map(
      (item) => <AMRPredictionRow prediction={item} isDiagnosticView={isDiagnosticView} />,
    );
  }

  const speciesName = `${identifiedSpecies.attributes.species.split(' ')[0][0]}. ${identifiedSpecies.attributes.species.split(' ')[1]}`;
  const speciesNameContent = identifiedSpecies.attributes.species_call_positive
    ? (<p><b>{speciesName}</b></p>)
    : (<p style={{ color: 'gray' }}>{speciesName}</p>);

  let positivityContent = null;
  if (!isDiagnosticView) {
    positivityContent = identifiedSpecies.attributes.species_call_positive
      ? (<p style={{ color: 'green' }}><b>Positive</b></p>)
      : (
        <p style={{ color: 'gray' }}>
          Present
          <small>(below threshold)</small>
        </p>
      );

  }

  const detailContentColor = identifiedSpecies.attributes.species_call_positive ? null : 'gray';

  let coverageContent = null;
  if (!isDiagnosticView && identifiedSpecies.attributes.perc_nonzero_coverage) {
    coverageContent = (
      <Box sx={{
          pt: 1, pb: 1, pl: 4, pr: 4, color: detailContentColor
      }}
      >
        <small>
          Reference genome coverage:
          &nbsp;{(identifiedSpecies.attributes.perc_nonzero_coverage * 100).toFixed(2)}
          %
        </small>
        <LinearProgress
          color="inherit"
          sx={{ mt: 1 }}
          variant="determinate"
          value={identifiedSpecies.attributes.perc_nonzero_coverage * 100}
        />
      </Box>
    );
  }

  let megabasesToSpeciesContent = null;
  if (!isDiagnosticView && identifiedSpecies.attributes.megabases_to_species) {
    megabasesToSpeciesContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor }}>
          {identifiedSpecies.attributes.megabases_to_species.toFixed(2)}
          {' '}
          MBs
        </p>
      </Grid>
    );
  }

  return (
    <Box>
      <Grid
        container
        sx={{
          pl: 1, pr: 1, textAlign: 'center', borderBottom: '#bdbdbd 2px solid',
        }}
      >
        <Grid item xs={12} sm={3} sx={{ textAlign: 'left' }}>
          {speciesNameContent}
        </Grid>
        <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            {positivityContent}
        </Grid>
        <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
            {coverageContent}
        </Grid>
        {megabasesToSpeciesContent}
      </Grid>
      <Box sx={{ pl: 10, textAlign: 'center', backgroundColor: '#eee' }}>
        {amrPredictionsContent}
      </Box>
    </Box>
  );
}

export default IdentifiedSpeciesRow;
