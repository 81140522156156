import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import LinearProgress from '@mui/material/LinearProgress';
import IdentifiedSpeciesRow from './IdentifiedSpecies/IdentifiedSpeciesRow';
import Toggle from './Buttons/Toggle';
import DetailedRunMetadataSummary from './Runs/DetailedRunMetadataSummary';
import DetailedSampleMetadataSummary from './Samples/DetailedSampleMetadataSummary';
import TestMetadataSummary from './Tests/TestMetadataSummary';
import DiagnosticSampleMetadataSummary from './Samples/DiagnosticSampleMetadataSummary';
import RunYieldSummary from './Runs/RunYieldSummary';
import UHETestSummary from './Tests/UHETestSummary';
import LPSeqTestSummary from './Tests/LPSeqTestSummary';
import TestPanel from './Tests/TestPanel';
import BackButton from './Buttons/BackButton';

function Sample() {
  const params = useParams();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [run, setRun] = useState(null);
  const [sample, setSample] = useState(null);
  const [activeAnalysis, setActiveAnalysis] = useState(null);
  const [activeAnalysisReports, setActiveAnalysisReports] = useState(null);
  const [identifiedSpecies, setIdentifiedSpecies] = useState(null);
  const [isDiagnosticView, setIsDiagnosticView] = useState(true);

  const handleIsDiagnosticViewChange = (event) => {
    setIsDiagnosticView(event.target.checked);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${params.runId}`,
            {
              method: 'GET',
              headers,
            },
          )
      })
      .then((res) => res.json())
      .then((response) => {
        setRun(response.data);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, params]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/lib_seqs/${params.sampleId}`,
            {
              method: 'GET',
              headers,
            },
          )
        })
      .then((res) => res.json())
      .then((response) => {
        setSample(response.data);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, params]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!sample) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/lib_seqs/${sample.id}/analyses?primary_only=True`,
            {
              method: 'GET',
              headers,
            },
          )
          })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data.length) {
          return;
        }

        setActiveAnalysis(response.data[0]);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, sample]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!activeAnalysis) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/analyses/${activeAnalysis.id}?extra_fields=pdf_report_signed_url,pdf_diagnostic_report_signed_url`,
            {
              method: 'GET',
              headers,
            },
          )
          })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data) {
          return;
        }

        setActiveAnalysisReports(response.data);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, activeAnalysis]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!activeAnalysis) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/analyses/${activeAnalysis.id}/identified_species`,
            {
              method: 'GET',
              headers,
            },
          )
          })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data) {
          return;
        }

        setIdentifiedSpecies(
          response.data.sort(
            (a, b) => a.attributes.species_call_rank - b.attributes.species_call_rank,
          ).slice(0, 5),
        );
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, activeAnalysis]);

  const titleText = isDiagnosticView ? 'OneSeq BSI Analysis Results' : 'Analysis Results';
  const toggleLabel = isDiagnosticView ? 'Diagnostic' : 'Internal';

  let downloadReportContent = null;
  if (activeAnalysisReports) {
    const downloadReportHref = isDiagnosticView
      ? activeAnalysisReports.attributes.pdf_diagnostic_report_signed_url
      : activeAnalysisReports.attributes.pdf_report_signed_url;

    downloadReportContent = (
      <a target="_blank" href={downloadReportHref} rel="noreferrer">
        <Button size="small" variant="contained" sx={{ color: 'white' }}><b>PDF REPORT</b></Button>
      </a>
    );
  }

  const runMetadataContent = isDiagnosticView
    ? <TestMetadataSummary run={run} sample={sample} isDiagnosticView={isDiagnosticView} />
    : <DetailedRunMetadataSummary run={run} />;

  const sampleMetadataContent = isDiagnosticView
    ? <DiagnosticSampleMetadataSummary sample={sample} />
    : <DetailedSampleMetadataSummary sample={sample} />;

  let identifiedSpeciesContent = <LinearProgress />;
  if (identifiedSpecies) {
    identifiedSpeciesContent = identifiedSpecies.filter(
      (item) => {
        if (!isDiagnosticView) {
          return true;
        }

        return item.attributes.species_call_positive;
      },
    ).map(
      (item) => (
        <IdentifiedSpeciesRow
          identifiedSpecies={item}
          isDiagnosticView={isDiagnosticView}
        />
      ),
    );
  }

  let yieldContent = null;
  if (!isDiagnosticView) {
    yieldContent = (
      <Grid item xs={12}>
        <Paper elevation={2}>
          <Box sx={{ backgroundColor: '#eee', padding: '1px 0px' }}>
            <RunYieldSummary analysis={activeAnalysis} />
          </Box>
        </Paper>
      </Grid>
    );
  }

  let uheDetailContent = null;
  let lpSeqDetailContent = null;
  let panelDetailContent = null;
  if (isDiagnosticView) {
    uheDetailContent = (
      <Grid item xs={12}>
        <Paper elevation={2}>
          <UHETestSummary />
        </Paper>
      </Grid>
    );

    lpSeqDetailContent = (
      <Grid item xs={12}>
        <Paper elevation={2}>
          <LPSeqTestSummary />
        </Paper>
      </Grid>
    );

    panelDetailContent = (
      <Grid item xs={12}>
        <Paper elevation={2}>
          <TestPanel />
        </Paper>
      </Grid>

    );
  }

  return (
    <Box>
      <Grid container sx={{ borderBottom: '#bdbdbd 2px solid' }}>
        <Grid item xs={12}>
          <Box>
            <Grid container maxWidth="lg" sx={{ m: 'auto', p: '10px' }}>
              <Grid item xs={2} sx={{ borderRight: '#bdbdbd 2px solid' }}>
                <Box>
                  <BackButton backToLink="/" backButtonText="ALL SAMPLES" />
                </Box>
              </Grid>
              <Grid item xs={4} align="left" sx={{ pt: 0.5, pl: 2 }}>
                {titleText}
              </Grid>
              <Grid item xs={2} align="right" sx={{ mt: -0.75, pl: 2 }}>
                <Toggle
                  handleChange={handleIsDiagnosticViewChange}
                  isChecked={isDiagnosticView}
                  label={toggleLabel}
                />
              </Grid>
              <Grid item xs={4} align="right">
                {downloadReportContent}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container maxWidth="lg" sx={{ m: 'auto', mb: 2, paddingRight: '30px' }} spacing={2} align="auto">
        <Grid item xs={12} sm={6}>
          <Paper elevation={2}>
            {runMetadataContent}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={2}>
            {sampleMetadataContent}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={2}>
                <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
                  <p>
                    <b>
                      { isDiagnosticView ? 'DETECTED' : 'IDENTIFIED'}
                      {' '}
                      SPECIES
                    </b>
                  </p>
                </Box>
                <Box>{identifiedSpeciesContent}</Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {yieldContent}
        {uheDetailContent}
        {lpSeqDetailContent}
        {panelDetailContent}
      </Grid>
    </Box>
  );
}

export default Sample;
