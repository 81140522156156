import React from 'react';
import { Box, Grid } from '@mui/material';

function DetailedSampleMetadataSummary(props) {
  const {
    sample,
  } = props;

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <Grid container>
          <Grid item xs={12}>
            <p><b>SAMPLE</b></p>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '1px 10px' }}>
        <p>
          <b>Name:</b>
          {' '}
          {sample?.attributes.sample_name || 'N/A'}
        </p>
        <p>
          <b>Sample ID:</b>
          {' '}
          {sample?.attributes.sample_id || 'N/A'}
        </p>
        <p>
          <b>Library ID:</b>
          {' '}
          {sample?.attributes.library_id}
        </p>
        <p>
          <b>Nugget Group:</b>
          {' '}
          {sample?.attributes.nugget_group || 'N/A'}
        </p>
        <p>
          <b>Type:</b>
          {' '}
          {sample?.attributes.sample_type}
        </p>
      </Box>
    </>
  );
}

export default DetailedSampleMetadataSummary;
