import React from 'react';

import { Box } from '@mui/material';

function DetailedRunMetadataSummary(props) {
  const {
    run,
  } = props;

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <p><b>SEQUENCING RUN</b></p>
      </Box>
      <Box sx={{ padding: '1px 10px' }}>
        <p>
          <b>Sequencing run name:</b>
          {' '}
          {run?.attributes.name}
        </p>
        <p>
          <b>Flow cell name:</b>
          {' '}
          {run?.attributes.flowcell_name || 'Unknown'}
        </p>
        <p>
          <b>Library prep kit:</b>
          {' '}
          {run?.attributes.library_prep_kit || 'Unknown'}
        </p>
        <p>
          <b>Barcoding kit:</b>
          {' '}
          {run?.attributes.barcoding_kit}
        </p>
        <p>
          <b>Date:</b>
          {' '}
          {run ? new Date(run.attributes.created_at).toDateString() : null}
        </p>
      </Box>
    </>
  );
}

export default DetailedRunMetadataSummary;
