import React, {useEffect, useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import RunStatusIndicator from './RunStatusIndicator'

import ontLogo from '../ont_logo.svg';
import illuminaLogo from '../illumina_logo.svg';

function RunStatus(props) {
    const {
        id,
        name,
        handleClick,
        activeRun
    } = props

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const [run, setRun] = useState(null);

    useEffect(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                const headers = new Headers({
                    Authorization: `Bearer ${accessToken}`,
                });

                return fetch(
                    `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${id}?extra_fields=status,yield_megabases,platform`,
                    {
                        method: 'GET',
                        headers,
                    },
                )
            })
            .then((res) => res.json())
            .then((response) => {
                setRun(response.data);
            })
            .catch((error) => console.error(error));
    }, [isAuthenticated, getAccessTokenSilently, id]);

    let platformLogo = null

    let statusText = '-'
    let yieldValue = '-'
    let createdAtValue = null
    if (run) {
        const yieldMegabases = run.attributes.yield_megabases
        yieldValue = yieldMegabases ?
            `${yieldMegabases.toLocaleString()} MBs` :
            '-'

        createdAtValue = new Date(run.attributes.created_at).toLocaleString()

        platformLogo = run.attributes.platform === 'illumina' ?
            illuminaLogo : ontLogo

        statusText = 'Uploading'
        if (run.attributes.status === 'basecalling') {
            statusText = 'Basecalling'
        } else if (run.attributes.status === 'analyzing') {
            statusText = 'Analyzing'
        } else if (run.attributes.status === 'delayed') {
            statusText = 'Delayed'
        } else if (run.attributes.status === 'failed') {
            statusText = 'Failed'
        } else if (run.attributes.status === 'succeeded') {
            statusText = 'Succeeded'
        }
    }

    let opacity = 1
    if (activeRun && (activeRun.id !== run.id)) {
      opacity = 0.5
    }

    return (
      <Grid item xs={12} sm={4}>
        <Paper
            elevation={2}
            sx={{ opacity: opacity, "&:hover": { boxShadow: 6, cursor: 'pointer' } }}
            onClick={handleClick}
        >
          <Box>
            <Grid container sx={{ borderBottom: 2, borderColor: '#ddd' }}>
                <Grid item xs={10} sx={{ padding: '0px 10px 0px 10px' }}>
                    <p style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}><b>{name}</b></p>
                </Grid>
                <Grid item xs={2} sx={{ padding: '12px 10px 0px 0px', textAlign: 'right' }}>
                    {platformLogo &&
                    (<img src={platformLogo} alt="platform-logo" height="25px" />)}
                </Grid>
            </Grid>
            <Grid container sx={{ padding: '0px 10px 0px 10px' }}>
                <Grid item xs={4} sx={{ padding: '15px' }}>
                    <Box sx={{ m: 1, position: 'relative'  }}>
                      <RunStatusIndicator run={run} />
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <p><b>Status:</b> {statusText}</p>
                    <p><b>Yield:</b> {yieldValue}</p>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{ fontSize: '0.75em', padding: '0px 20px', backgroundColor: '#eee' }}
            >
                <Grid item xs={12}>
                    <p><b>Created:</b> {createdAtValue}</p>
                </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    )
}

export default RunStatus;
