import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useAuth0, withAuth0 } from '@auth0/auth0-react';

function UserDropdown() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const { getIdTokenClaims, isAuthenticated, logout } = useAuth0();
  const [userInfo, setUserInfo] = React.useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      setUserInfo(null);
    } else {
      setUserInfo(getIdTokenClaims);
    }
  }, [isAuthenticated, getIdTokenClaims]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={userInfo?.name || ''} src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',

        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',

        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key="logout" onClick={logout}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default withAuth0(UserDropdown);
