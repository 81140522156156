import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

function RunStatusIndicator(props) {
    const {
        run,
    } = props

    let statusIcon = <PendingIcon />
    let statusIconColor = 'default'
    let showProgress = true
    if (run) {
        statusIcon = <CloudUploadIcon />
        statusIconColor = 'default'
        showProgress = true
        if (run.attributes.status === 'basecalling') {
            statusIcon = <BlurLinearIcon />
            statusIconColor = 'primary'
        } else if (run.attributes.status === 'analyzing') {
            statusIcon = <LensBlurIcon />
            statusIconColor = 'primary'
        } else if (run.attributes.status === 'delayed') {
            statusIcon = <WatchLaterIcon />
            statusIconColor = 'warning'
        } else if (run.attributes.status === 'failed') {
            statusIcon = <ErrorIcon />
            statusIconColor = 'error'
            showProgress = false
        } else if (run.attributes.status === 'succeeded') {
            statusIcon = <CheckCircleIcon />
            statusIconColor = 'success'
            showProgress = false
        }
    }

    let progressContent = null;
    if (showProgress) {
        progressContent = (
          <CircularProgress
              color={statusIconColor}
              size={49}
              sx={{
                  position: 'absolute',
                  top: -4.35,
                  left: -4.35,
                  zIndex: 1
              }}
          />
        )
    }

    return (
        <>
          <Fab
              aria-label="save"
              color={statusIconColor}
              size="small"
          >
            {statusIcon}
          </Fab>
          {progressContent}
        </>
    )
}

export default RunStatusIndicator;
