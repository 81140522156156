import React from 'react';

import { FormGroup, FormControlLabel, Switch } from '@mui/material';

function Toggle(props) {
  const {
    handleChange,
    isChecked,
    label,
  } = props;

  const toggleControlContent = (
    <Switch
      defaultChecked
      onChange={handleChange}
      checked={isChecked}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );

  return (
    <FormGroup>
      <FormControlLabel
        control={toggleControlContent}
        label={label}
      />
    </FormGroup>
  );
}

export default Toggle;
